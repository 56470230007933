@font-face {
  font-family: "Roboto";
  src: url(../fonts/Roboto/Roboto-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url(../fonts/Roboto/Roboto-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url(../fonts/Roboto/Roboto-Bold.ttf);
  font-weight: 600;
}

/* Modal Backdrop fix */

.modal{
  z-index: 1056;
}
.modal-backdrop {
  z-index: 1055;
}

.pTop {
  padding-top: 1.5rem;
}
.pt-70{
  padding-top: 70px !important;
}

/* #root>div,
#root>div>div {
    z-index: unset;
} */

/*  */
html, body{
  max-width: 100%;
    overflow-x: hidden;
    overscroll-behavior-y: none;
}
body {
  font-family: "Roboto", sans-serif;
  background-color: #f1f1f1;
  font-weight: 400;
  max-width: 100%;
  overflow-x: hidden;
  /* letter-spacing: 0.4px !important; */
  font-size: 14px;
  line-height: 1.4;
  color: #333333;
}

:root {
  --prime: #4a5fbe;
}

#map-test {
  height: 500px;
  position: relative;
}

.capitalize {
  text-transform: capitalize !important;
}

.fs-7 {
  font-size: 14px !important;
}

.small {
  font-size: 12px !important;
}

/* #map {
        position: relative;
    } */

#map-test:after {
  width: 22px;
  height: 40px;
  display: block;
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -40px 0 0 -11px;
  background-size: 22px 40px;
  pointer-events: none;
}

.page-body {
  display: flex;
  display: -ms-flexbox;
  padding-top: 50px;
}

.page-content {
  width: 100%;
  margin-top: 9vh;
}

.page-content-home {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  /* letter-spacing: 0.5px; */
  font-weight: 500;
}

p {
  color: #444444;
  font-size: 14px;
  font-weight: 500;
}

small {
  color: #888888;
  font-weight: 500;
  /* font-size: 12px !important; */
  letter-spacing: 0.4px;
}

label {
  color: #888;
}

.form-label {
  color: #555454 !important;
  font-size: 13px !important;
}

.text-dark {
  color: #000 !important;
}

.text-green {
  color: #008000 !important;
}

.text-red {
  color: #d30000;
}

.text-prime {
  color: var(--prime);
}

.text-gray {
  color: gray !important;
}

.text-darkgray {
  color: #525252 !important;
}

.text-grey-1 {
  color: #505559 !important;
}

.btn-prime {
  background-color: var(--prime) !important;
  color: #fff !important;
  font-weight: bold !important;
  border-radius: 20px;
}

.btn-outline-prime {
  border-color: var(--prime) !important;
  color: var(--prime) !important;
}

.btn-outline-prime:hover {
  border-color: var(--prime) !important;
  background-color: var(--prime) !important;
  color: #fff !important;
}

.bg-fff {
  background-color: #fff !important;
}

.bg-gray {
  background-color: #808080 !important;
}
.bg-light-grey {
  background-color: #aaaaaa !important;
}

.bg-prime {
  background-color: var(--prime);
  color: #fff !important;
}

.bg-light {
  background-color: #f9f9f9 !important;
}

.bg-green {
  background-color: #008000 !important;
}

.bg-lightPrime {
  background-color: #4b4abe29 !important;
}

.bg-light-blue {
  background-color: #dfecff;
}

.list:hover {
  background: rgb(222, 233, 251) !important;
  border-radius: 3px;
}

.head-bar {
  /* background: #fff !important; */
  padding: 14px 20px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  position: sticky;
  /* top: 9vh; */
  z-index: 10;
}

.card {
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 6px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.end {
  display: flex;
  align-items: center;
  justify-content: end;
}

.btn-primary-outline {
  border: 1px solid #0d6efd;
  border-radius: 5px;
  color: #0d6efd;
  background-color: #fff;
}

.btn-primary-outline:hover {
  color: #fff;
  background-color: #0d6efd;
}

.btn-secondary-outline {
  border: 1px solid #aaa;
  background: #fff;
  color: #222;
}

.MuiSlider-root {
  color: var(--prime) !important;
  cursor: pointer;
}

.form-check-input:checked {
  background-color: var(--prime) !important;
  border-color: var(--prime) !important;
}

.radius {
  border-radius: 6px;
}

/* Sidebar css */

.sidebar {
  color: #000 !important;
  width: 100%;
  background: #fff;
  transition: margin-left 0.3s ease;
  box-shadow: 0px 1px 5px #ccc;
  position: fixed;
  z-index: 17;
  padding: 10px 0px;
  bottom: 0;
  height: 93vh;
}

.sidebar-options {
  display: flex !important;
  justify-content: end;
  align-items: center;
}

.sidebar-footer {
  margin-top: 40px;
  padding: 0 10px;
  margin-bottom: 40px;
}

.sidebar-sticky {
  /* width: 100%; */
  /* margin-left: 15px;
    margin-right: 15px; */
  /* overflow-y: auto; */
  /* height: calc(100vh - 350px); */
  /* scrollbar-width: none !important; */
}

.sidebar-header {
  position: sticky;
  padding: 10px;
}

.sidebar-brand {
  height: 90px;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 500;
  color: #555 !important;
}

.sidebar-brand img {
  max-height: 90px;
}

.page-sidebar {
  display: none;
}

.sidebar-sticky ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-sticky ul li a {
  margin-bottom: 5px;
  padding: 10px;
  display: block;
  text-decoration: none;
  color: #727272 !important;
  font-size: 12px;
  font-weight: 600 !important;
  /* font-family: "poppins-medium"; */
  letter-spacing: 0.8px;
  /* text-transform: uppercase; */
  /* border-bottom: 1px solid #eee; */
}

.sidebar-sticky > ul > li:first-child > a {
  /* border-top: 1px solid #eee; */
}

.sidebar-sticky ul > li > a.active ~ .sidebar-dropdown-menu ul > li > a {
  /* background: #f8f8f8; */
}

.sidebar-sticky ul > li > a:hover,
.sidebar-sticky ul > li > a.active {
  background: #f3def7 !important;
  /* border-bottom-color: #eee; */
  border-right: 3px solid var(--prime) !important;
  color: var(--prime) !important;
  /* border-radius: 10px; */
}

.sidebar-sticky ul > li > a:hover,
.sidebar-sticky ul > li > a.active p {
  color: var(--prime) !important;
}

.sidebar-sticky ul li a p {
  font-weight: 600 !important;
  /* font-family: "poppins-medium"; */
  color: #dfdfdf;
  font-size: 12px !important;
  letter-spacing: 0.8px !important;
}

.sidebar-sticky ul > li > a > i {
  margin-right: 6px;
  color: #000;
  width: 20px;
  text-align: center;
}

.sidebar-sticky ul > li > a > img {
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
}

.sidebar-sticky ul > li > a > span {
}

.sidebar-dropdown .sidebar-dropdown-menu {
  display: none;
  padding-top: 3px;
}

.sidebar-dropdown .sidebar-dropdown-menu ul li a {
  padding-left: 30px;
}

.sidebar-dropdown .sidebar-dropdown-btn.active ~ .sidebar-dropdown-menu {
  display: block;
}

.sidebar-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  padding: 10px;
  z-index: 1301;
  box-shadow: none !important;
}

.sidebar-close span:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 25%;
  width: 100%;
  height: 100%;
  border-left: 2px solid #888;
  transform: rotate(45deg);
}

.sidebar-close span:after {
  content: "";
  position: absolute;
  top: 50%;
  left: -40%;
  width: 100%;
  height: 100%;
  border-right: 2px solid #888;
  transform: rotate(-45deg);
}

.sidebar-dropdown-arrow {
  float: right;
  margin-top: 2px;
}

.sidebar-toggler {
  position: relative;
  outline: 0 !important;
  box-shadow: none !important;
  border: 0;
  padding: 0 0 0 10px;
}

.sidebar-toggler span {
  width: 22px;
  height: 2px;
  background-color: #333;
  margin: 4px 0;
  display: block;
}

.sidebar-username {
  text-decoration: none !important;
  color: #6c757d !important;
}

.sidebar-options > a,
.sidebar-options > button {
  background: transparent;
  border: 0;
  outline: 0;
  text-decoration: none !important;
  color: #fff;
  margin-top: 5px;
  font-size: 16px;
  padding: 10px;
  border-radius: 10px;
}

.sidebar-options > a:hover,
.sidebar-options > button:hover {
  background: rgba(255, 255, 255, 0.2);
}

/*  */
.img-radius {
  border-radius: 50%;
}

/* Navbar css */

.navbar {
  justify-content: flex-start !important;
}

.navbar-custom {
  background: #fff;
  height: 8vh;
  border-bottom: 1px solid #f3f3f3;
  z-index: 16;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.navbar-custom .logo-img {
  max-height: 35px;
  max-width: 200px;
}

.navbar-custom-new {
  background: #fff;
  height: 50px;
  border-bottom: 1px solid #f3f3f3;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.navbar-custom-new .logo-img {
  max-height: 35px;
  max-width: 200px;
}

.navbar-custom h4 {
  color: var(--prime) !important;
}

.navbar-custom {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 20px;
  padding-right: 0px;
}

.navbar-custom .nav-link {
  padding: 15px 10px;
  color: #fff;
  font-weight: 500;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-custom .nav-link > img {
  width: 28px;
}

.navbar-custom-new {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 20px;
  padding-right: 0px;
}

.navbar-custom-new .nav-link {
  padding: 15px 10px;
  color: #fff;
  font-weight: 500;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-custom-new .nav-link > img {
  width: 28px;
}

.navbar-search > .dropdown {
  width: 100%;
}

.navbar-profile {
  color: #fff;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 20px;
}

.navbar-profile-sm {
  max-width: 100px;
  max-height: 100px;
  border-radius: 100%;
  margin-bottom: 5px;
}

/*  */

/* Modal css */

.scan .modal-dialog {
  margin: 1rem !important;
}

.scan img {
  width: 150px !important;
  height: 150px !important;
}

/* .modal-header {
    border-bottom: none !important;
}

.modal-content {
    border-radius: 0px !important;
}

.modal-footer {
    border-top: none !important;
} */

/* .modal-dialog { */
/* height: 100vh; */
/* } */

/* .modal {
    padding: 0px !important;
} */

/*  */

a {
  text-decoration: none !important;
  color: #000;
}

.btn {
  font-size: 14px;
}

.btn:focus {
  box-shadow: none !important;
}

.btn-sm {
  font-size: 13px;
  font-weight: 500;
}

.btn-green {
  background-color: #008000 !important;
  border-color: #008000 !important;
  color: #fff !important;
}

.btn-red {
  background-color: #ff0000;
  border: #d30000;
  color: #fff;
}

.btn-reject {
  border: 1px solid #ccc !important;
  color: #222;
  border-radius: 20px;
}

.text-shrink {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.toast.notification {
  cursor: pointer;
  border: 0;
  position: fixed;
  z-index: 1200;
  bottom: 20px;
  left: 50%;
  margin-left: -175px;
  width: 100%;
  background: #333;
  color: #fff;
}

.custom-swipe {
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.dropdown-menu {
  font-size: 14px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: none !important;
  z-index: 1 !important;
  padding: 5px;
}

.form-control,
.form-select {
  font-size: 14px;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.form-control:hover,
.form-select:hover {
  border-color: #4d57eb;
}

.form-control:focus,
.form-select:focus {
  border-color: none !important;
  box-shadow: none !important;
}

.input-group-text {
  font-size: 14px;
}

.cursor {
  cursor: pointer;
}

.pagination\:container {
  display: flex;
  align-items: center;
  justify-content: end;
}

.arrow\:text {
  display: block;
  vertical-align: middle;
  font-size: 13px;
}

.pagination\:number {
  --size: 32px;
  --margin: 6px;
  margin: 0 var(--margin);
  border-radius: 6px;
  background: #e1e0e0;
  max-width: auto;
  min-width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 6px;
}

.pagination\:number:hover {
  background: lighten(#202020, 3%);
}

.pagination\:number.active {
  background: lighten(#202020, 3%) !important;
}

.pagination\:active {
  background: #4891ff !important;
  color: #fff !important;
  position: relative;
}

.btn-blue-outline {
  border: 1px solid #0d6efd;
  border-radius: 6px;
  color: #0d6efd;
  font-size: 12px;
  text-align: center;
  padding: 5px;
}

.btn-red-outline {
  border: 1px solid #d30000;
  color: #d30000;
  background-color: #fff;
}

.btn-orange-outline {
  border: 1px solid #e78300;
  border-radius: 6px;
  color: #e78300;
  font-size: 12px;
  text-align: center;
  padding: 5px;
}

.tabs {
  display: flex;
}

.tabs .tabs-head {
  padding: 0px 10px;
  cursor: pointer;
}

.tabs .tabs-head p {
  font-size: 13px;
  padding: 6px 10px;
}

.active_tab p {
  color: var(--prime);
  font-weight: 600 !important;
  background: #fddfec;
  border-radius: 20px;
}

.f_tab {
  cursor: pointer;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #dddddd !important;
  outline: 0;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: #000 !important;
  background-color: #f9f9f9 !important;
}

.accordion-button {
  padding: 1rem !important;
}

.accordion-button::after {
  width: 1.2rem !important;
  height: 1.2rem !important;
  background-size: 1.2rem !important;
}

.accordion-item {
  background-color: #fff;
  border: none !important;
}

.accordion-item:last-of-type .accordion-collapse {
  margin-top: 10px;
}

.accordion-body {
  padding: 0 !important;
}

.product-img {
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
}

.MuiDrawer-paperAnchorBottom {
  /* min-height: 120px; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.MuiDrawer-paperAnchorBottom ul {
  list-style: none !important;
}

.MuiAccordion-root::before {
  background-color: transparent !important;
}

.MuiDrawer-paperAnchorRight {
  width: 30%;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin-bottom: 0 !important;
}

.MuiAccordionSummary-content {
  align-items: center;
}

.MuiAccordionSummary-expandIcon {
  transform: rotate(0deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.scrollbar-none {
  scrollbar-width: none !important;
}

.h-200px {
  height: 200px !important;
}

.w-100px {
  width: 100px !important;
}

.w-40px {
  width: 40px;
}

.w-70 {
  width: 70%;
}

.w-30 {
  width: 30%;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: auto !important;
}

.css-b62m3t-container {
  z-index: 9;
}

.Price {
  z-index: 8 !important;
}

.bottom-tabs {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45px;
  z-index: 2;
  background: #fff;
}

.bottom-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45px;
  z-index: 1;
}

.rs-table-hover .rs-table-row:hover {
  background-color: #e0f4fa !important;
}

.para-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.disc-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 80px;
}

.select__control,
.select__menu {
  text-align: left !important;
}

.dropdown-toggle::after {
  margin-left: 0 !important;
  content: none !important;
}

.scrollY70 {
  overflow-y: scroll !important;
  min-height: 40vh;
  max-height: 70vh !important;
  overflow-x: hidden !important;
  scrollbar-width: none;
}

.scrollY90 {
  overflow-y: scroll !important;
  min-height: 40vh;
  max-height: 90vh !important;
  overflow-x: hidden !important;
  scrollbar-width: none;
}

.create_acc {
  width: 100%;
  height: 20vh;
  margin-bottom: 20px;
}

.inherit {
  width: inherit;
}

.svg-align {
  width: 100% !important;
  height: 35px !important;
  padding-left: 80px !important;
}

.history .MuiAccordionSummary-root {
  padding: 0px !important;
}

.history .MuiAccordionSummary-content {
  margin: 0 !important;
  display: block !important;
}

.history .MuiAccordionDetails-root {
  display: block !important;
  padding: 0px !important;
}

/* .bg-store{
    background-color: #ecfdec;
    border-radius: 5px;
} */

/* Media quries */

@media (min-width: 768px) {
  .md-text-end {
    text-align: right !important;
  }

  .d-md-block {
    display: block;
  }

  .md-ps-0 {
    margin-left: 0 !important;
  }

  .bottom {
    bottom: 0px;
    position: fixed;
    width: 30%;
  }

  .md-w-50 {
    width: 50% !important;
  }
}

@media (max-width: 768px) {
  /* .modal-dialog {
        max-width: 100% !important;
        margin: 0px !important;
    } */

  .sm-p-0 {
    padding: 0px !important;
  }

  .sm-mt-3 {
    margin-top: 1rem !important;
  }

  .d-md-none {
    display: none !important;
  }

  .d-sm-none {
    display: none;
  }

  .d-sm-block {
    display: block !important;
  }

  .sm-mb-2 {
    margin-bottom: 0.5rem;
  }

  .bottom {
    bottom: 0px;
    position: fixed;
    width: 100%;
    left: 0px;
  }

  .sm-w-100 {
    width: 100% !important;
  }
}

@media (max-width: 991px) {
  .page-content {
    margin-top: 9vh !important;
  }

  .sidebar {
    transition: 6.5s ease-in-out;
    display: none;
  }

  .sidebar.active {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    /* width: calc(100% - 25%); */
    display: block;
    transition-property: top, left;
    transition-duration: 1s, 1s;
    transition-delay: 0s, 1s;
  }

  .sidebar-footer {
    margin-top: 40px;
    margin-bottom: 0;
  }

  .navbar-search {
    display: none;
  }

  .navbar-search.active {
    position: absolute;
    left: 10px;
    right: 10px;
    background: #fff;
    z-index: 111111;
    top: 0;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }

  .navbar-search.active > .dropdown {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .page-sidebar {
    width: 27%;
  }

  /* .page-content {
        margin-left: 220px;
    } */

  .navbar-search {
    width: 25%;
  }

  .navbar-custom .logo-img {
    max-height: 35px;
    width: auto;
    max-width: 200px;
  }

  .navbar-custom-new .logo-img {
    max-height: 35px;
    width: auto;
    max-width: 200px;
  }

  .navbar-brand.navlogo {
    width: 17%;
    text-align: center;
  }

  .sidebar-sticky::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }

  .sidebar-sticky::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2);
  }

  .sidebar-sticky::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
  }

  .sidebar-sticky::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.4);
  }

  .sidebar-sticky {
    scrollbar-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.2);
    scrollbar-width: thin;
  }

  .sidebar {
    margin-left: 0;
    width: 220px;
  }

  .sidebar .sidebar-header .sidebar-close {
    display: none;
  }
}

.profile-img {
  width: 100%;
  object-fit: cover;
  background: no-repeat;
  border-radius: 50%;
  height: 50px;
}

.mob_sidemenu {
  width: 90% !important;
  height: 100vh !important;
  overflow-y: scroll;
  scrollbar-width: none;
  border-radius: 0 !important;
}

.shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.menu_active:hover,
.menu_active {
  color: #4b4abe !important;
}

.border {
  border: 1px solid #f0f0f0 !important;
  border-radius: 5px;
}

.border-right {
  border-right: 1px solid #ccc !important;
}

.border-prime {
  border: 1px solid var(--prime);
}

.border-bottom-prime {
  border-bottom: 2px solid var(--prime);
}

.map-over {
  z-index: 9;
  position: absolute;
  height: auto !important;
  border-radius: 0 !important;
  width: 100%;
}

.gmnoprint {
  bottom: 130px !important;
}

.gm-style-cc {
  display: none !important;
}

.filter-headroom .headroom--scrolled {
  transition: transform 200ms ease-in-out;
}

.filter-headroom .headroom--pinned {
  position: fixed;
  transform: translate3d(0px, -100%, 0px) !important;
  top: 100px !important;
  box-shadow: none !important;
}

.filter-headroom .headroom--unpinned {
  position: fixed;
  transform: translateY(-100%);
  top: 100px !important;
  box-shadow: none !important;
}

.tablist {
  overflow-y: scroll;
  scrollbar-width: none !important;
  -ms-scrollbar-width: none !important;
  position: fixed;
  /* border-top-left-radius: 20px;
border-top-right-radius: 20px; */
  z-index: 10;
  width: 100%;
  left: 0;
  padding: 0;
}

.tablist::-webkit-scrollbar {
  display: none !important;
}

.react-tabs__tab--selected::after {
  border: none !important;
  display: none !important;
}

.react-tabs__tab--selected {
  border-bottom: 2px solid var(--prime) !important;
  color: var(--prime) !important;
  box-shadow: none !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 20px;
  font-weight: 600;
}

.react-tabs__tab {
  bottom: 0 !important;
}

.react-tabs__tab--selected p {
  color: var(--prime) !important;
  font-weight: 600;
}

.radius20 {
  border-radius: 20px;
}

.v-small {
  font-size: 10px !important;
  letter-spacing: 0.6px;
}

.text-black {
  font-weight: 500 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.radius-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.lineh-20 {
  line-height: 20px;
}

.MuiRating-label {
  font-size: 20px;
  color: orange !important;
}

.cursor-none {
  pointer-events: none;
}

.opacity-5 {
  opacity: 0.65;
}

.package-image {
  width: 100%;
  height: 100px;
  object-fit: fill;
  border-radius: 3px;
}

.bank-img {
  width: 20px;
  height: 20px;
}

.react-datepicker {
  font-family: "poppins";
  font-size: 0.8rem;
  background-color: #fff;
  color: #222;
  border: none !important;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #4b4abe !important;
}

.react-datepicker__header {
  background-color: #f5f4f4 !important;
  border-bottom: 1px solid #ececec !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #4b4abe !important;
}

.react-datepicker__navigation {
  align-items: center !important;
  top: 6px !important;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  margin: 5px !important;
  padding: 4px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-bottom: 7px;
}

.custom-bottom {
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.delbrand-img {
  width: 25px;
  border-radius: 50%;
  height: 25px;
}

.order-sticky {
  position: sticky;
  top: 50px;
  z-index: 2;
  background-color: #fff;
  padding: 5px 0px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

/** Cupertino Panes Css */
.swipeable-drawer-container-footer .cupertino-pane-wrapper .draggable{
  padding-top: 8px;
}
.swipeable-drawer-container-footer .cupertino-pane-wrapper .move {
  height: 3px;
  width: 50px;
}
.cupertino-pane-wrapper.swipeable-drawer .pane {
  cursor: default !important;
  padding: 0;
  padding-top: 10px;
  /* border-radius: 0; */
  /* background: transparent;
  box-shadow: none; */
}
.swipeable-drawer-container-footer .cupertino-pane-wrapper.swipeable-drawer .pane {
  padding-top: 5px;
}

.swipeable-drawer-content {
  /* background: #ffffff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12); */
  height: 100%;
}
/* .ord_detail_drawer {
  position: relative;
  z-index: 1;
}
.ord_detail_drawer2 {
  position: absolute;
  z-index: 1032;
  top: -50px;
} */
.ord_detail_drawer .cupertino-pane-wrapper{
  position: fixed;
  z-index: 1051;
}
.ord_detail_drawer2 .cupertino-pane-wrapper{
  position: fixed;
  z-index: 1060;
}

.swipeable-drawer-content.rounded-corner {
  border-radius: 10px;
}
.bar {
  height: 3px;
  width: 50px;
  background: #ccc;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

.z-index-50 {
  z-index: 50;
}
.z-index-1050 {
  z-index: 1050;
}

.box-1 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background: #ffffff;
  border-radius: 6px;
}
.box-2 {
  box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.09);
  background: #ffffff;
  border-radius: 13px;
}
.box-3 {
  background: #ffffff;
  border-radius: 6px;
}

.br-100 {
  border-radius: 100%;
}
.br-20 {
  border-radius: 20px;
}
.br-8 {
  border-radius: 8px;
}
.br-0 {
  border-radius: 0;
}

.pb-100 {
  padding-bottom: 100px;
}

.navbar-brand {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 22px;
}

.navbar {
  box-shadow: 0 0 6px #cccccc;
}

.link-text,
.link-text:hover,
.link-text:active,
.link-text:focus-visible {
  text-decoration: none;
  color: #333333;
}

.profile-div {
  border-radius: 100%;
  overflow: hidden;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
}

.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}

.fs-10 {
  font-size: 10px;
}
.fs-20 {
  font-size: 20px;
}
.fs-25 {
  font-size: 25px;
}
.fs-30 {
  font-size: 30px;
}

.py-13{
  padding-top: 13px;
  padding-bottom: 13px;
}


.tabs-menu {
  display: flex;
}
.tab-menu-item {
  padding: 5px;
  text-decoration: none;
  color: #222;
  flex: 1 1 auto;
  border-bottom: 2px solid transparent;
  text-align: center;
}
.tab-menu-item.active {
  color: #0d6efd;
  border-bottom-color: #0d6efd;
  font-weight: 500;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.fade-in {
  animation: fadeIn 1s;
}
.modal.fade.fade-up .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, 50px);
}

.modal.fade-up.show .modal-dialog {
  transform: none;
}

.menu-item {
  padding: 10px;
  border-radius: 10px;
  color: #000;
}
.menu-item:hover {
  background: #f1f1f1;
}

.menu-list-item {
  padding: 10px;
  color: #000;
  border-bottom: 1px solid #f1f1f1;
}
.menu-list-item:hover {
  background: #f1f1f1;
}

.menu-list .menu-list-item:last-child {
  border-bottom: 0;
}

.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.dotted-v-line {
  height: 100%;
  width: 0;
  border-left: 1px dashed #cccccc;
  margin-left: auto;
  margin-right: auto;
}
.alert-badge {
  color: #fff;
  background-color: #ea680b;
  text-transform: uppercase;
  padding-top: 6px;
  letter-spacing: 0.5px;
}
.success-badge {
  color: #fff;
  background-color: rgb(25, 135, 84);
  text-transform: uppercase;
  padding-top: 6px;
  letter-spacing: 0.5px;
}
.light-badge {
  background: #e3e3e3;
  padding: 2px 4px;
  font-size: 12px;
  border-radius: 5px;
  color: #676767;
  margin-right: 5px;
}
.px-35 {
  padding-left: 35px;
  padding-right: 35px;
}


.bottom-buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1055;
  box-shadow: 0 0 8px #aaaaaa;
}

.btn-circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  font-size: 15px;
  padding: 0;
  background: #f2f7ff;
  display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.order-details-accordion .MuiAccordionSummary-content{
  margin: 0 !important;
}
.order-details-accordion .accordion-head{
  background-color: #f9f9f9;
  padding: 10px;
  min-height: auto;
  border-radius: 8px;
  font-weight: 500;
}

.bg-circle{
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d5ffc8;
  color: #007d50;
}
.bg-circle.cardboard-color{
  color: #A37837;
  background-color: #ffeccf;
}
.bg-circle.violet-color{
  color: #554C7A;
  background-color: #efecff;
}




.dashboard-app-btns {
	display: flex;
  flex-wrap: wrap;
}
.dashboard-app-btns .dashboard-app-btn-col {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 3px;
}
.dashboard-app-btns .dashboard-app-btn {
	display: flex;
	align-items: center;
	/* justify-content: center; */
	flex-direction: column;
	width: 25%;
	/* border: 1px solid #dee2e6; */
	border-radius: 6px;
	text-align: center;
	padding: 5px 2px;
	margin: 2px;
	text-decoration: none;
  box-shadow: 0px 1px 2px #d0d0d0;
  border: 1px solid #f1f1f1;
  width: 100%;
  height: 100%;
  position: relative;
}
.dashboard-app-btns .dashboard-app-btn img{
	max-height: 40px;
}
.dashboard-app-btns .dashboard-app-btn .dashboard-app-btn-title{
	font-size: 12px;
	font-weight: 500;
	text-transform: none;
}
.btn-menu-back{
	border-radius: 50%;
	height: 35px;
    /* width: 35px; */
	padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 5px;
    padding-left: 10px;
    color: #343a40 !important;
    font-size: 12px;
}
.superapp_menu_tabs{
	border-top: 1px solid #f1f1f1;
}


.swipeable-drawer-container-footer .cupertino-pane-wrapper{
  z-index: 1031;
}

.package-img{
  max-height: 200px;
}


/* Lazy Loading Css */
.lazy-load:has(.lazy), .lazy-img, .lazy-div {
	--bg: #f1f1fa;
	--glare-bg: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.5), transparent);
	position: relative;
	background-color: var(--bg);
	overflow: hidden;
}
.lazy-load:has(.lazy)::before, .lazy-img::before, .lazy-div::before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: var(--glare-bg);
	transform: translateX(-100%);
	animation: skeleton-glare 1.75s infinite ease-out;
	z-index: 1;
}
@keyframes skeleton-glare {
	to {
		transform: translateX(100%)
	}
}
.lazy-load:has(.lazy) > *, .lazy-img > *, .lazy-div > *{
  visibility: hidden;
}


.btn-loader {
  border-top: 0.2em solid currentcolor;
  border-right: 0.2em solid transparent;
  -webkit-animation: btn-loader 1s linear infinite;
          animation: btn-loader 1s linear infinite;
  border-radius: 100%;
  position: relative;
  display: inline-block;
    width: 1em;
    height: 1em;
    color: inherit;
    vertical-align: middle;
    pointer-events: none;
}

@-webkit-keyframes btn-loader {
  to {
    transform: rotate(360deg);
  }
}

@keyframes btn-loader {
  to {
    transform: rotate(360deg);
  }
}



.tutorial-tooltip-overlay {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #00000080;
  z-index: 1040;
}
.tutorial-tooltip {
  background-color: transparent;
  text-align: center;
  border-radius: 6px;
  padding: 0;
  position: fixed;
  z-index: 1041;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
    flex-direction: column;
}

.tutorial-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}



.timeline-1 .timeline-dot{
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.timeline-1 .timeline-dot.active{
  background-color: #0057ff;
}
.timeline-1 .timeline-line-container{
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeline-1 .timeline-line{
  height: 30px;
  width: 3px;
  background-color: #cccccc;
}
.timeline-1 .timeline-line.active{
  background-color: #0057ff;
}

.stat-pic{
  min-height: 76px;
}

/* Sendbird chat css */
.chat-header{
  height: 60px;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  align-items: center;
}
.chat-header .profile-image {
  max-width: 30px;
  max-height: 30px;
  border-radius: 100%;
  margin-right: 10px;
}
.sendbird-hide-header .sendbird-conversation .sendbird-chat-header{
  display: none;
}
.sendbird-app-container .sendbird-fileviewer{
  top: 60px;
}
.sendbird-app-container .sendbird-fileviewer .sendbird-fileviewer__header__left__avatar,
.sendbird-app-container .sendbird-fileviewer .sendbird-fileviewer__header__left__sender-name{
  display: none !important;
}
.sendbird-app-container .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__filename{
  max-width: 150px;
  font-size: 15px;
}
.sendbird-app-container .sendbird-fileviewer .sendbird-fileviewer__content{
  height: calc(100% - 132px);
}
.w-10px {
  width: 10px;
}
.icon-14px {
  width: 14px;
  height: 14px;
}
.delivery_profile_img {
  max-height: 120px;
  max-width: 120px;
  border-radius: 6px;
  object-fit: cover;
}


.rsbcText{
  color: #4b4b4b !important;
    text-transform: uppercase;
    font-weight: 500;
}